
export const API_URL = process.env.NEXT_PUBLIC_API_BASE_URL;
export const API_KEY = process.env.NEXT_PUBLIC_API_KEY;

export const checkLoginStatus = () => {
    const AUTH_TOKEN = localStorage.getItem('AUTH_TOKEN');
    
    // TODO: server-side validation
    if (AUTH_TOKEN){
        return true;
    }

    return false;
};

export async function login (username, password) {
    const apikey = API_KEY; 
    let loginResult;
    try {
      const response = await fetch(`${API_URL}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        loginResult = {result: true, 'AUTH_TOKEN': data.token};
      } else {
        loginResult = {result: false};
      }
    } catch (error) {
        loginResult = {result: false, error: error};
    }
    
    return loginResult
};